<template>
  <div class="home">
    <main>
      <h1>Einsatzabteilung der Freiwilligen Feuerwehr Lunestedt</h1>
    <article class="aktivmain">
      
      <h2>Allgemeine Infos zur Einsatzabteilung</h2>
        <p>Derzeit verrichten in der Einsatzabteilung über 70 Frauen und Männer ihren Dienst zum Wohle am Nächsten. 
          Zum Übungsdienst trifft man sich jeweils am ersten Montag und dritten Donnerstag eines Monats sowie zu weiteren Sonderdiensten. 
          Geleitet wird die Einsatzabteilung durch den Ortsbrandmeister und seinen Stellvertreter. 
          Unterstützung erhält das Führungsduo dabei durch unsere Zug- und Gruppenführer.
      <br></p>
      <div><button @click="isVisible = !isVisible">Bilder</button>
      <div class="bild_parent" v-if="isVisible">
        <div v-for="item in allgemein" :key="item" class="bilder">
          <img :src="require(`@/components/images/aktiv/allgemeines/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div>
      
      <br>
      <h2>Was macht die Einsatzabteilung</h2>
      <p>Bei den Übungsdiensten steht die feuerwehrtechnische Ausbildung gemäß Dienstvorschriften im Vordergrund. 
        Dazu zählen beispielsweise Einsatz-, Atemschutz- oder Funkübungen und Geräte-, Orts- sowie Objektkunde. 
        Auch Übungen mit Leinen und Leitern, Gefahren an der Einsatzstelle, Sicherheitsunterweisungen, Erste Hilfe und vieles mehr werden thematisiert. 
        Hierbei unterstützen uns auch Berufsfeuerwehren sowie externe Unternehmen mit ihren Dozenten und Ausbildern. 
        Zudem werden Lehrgänge auf Kreis- und Landesebene regelmäßig besucht.
      </p><p>Die Stützpunktfeuerwehr Lunestedt wird bei Einsätzen entsprechend der AAO (Alarm- und Ausrückordnung) alarmiert. 
        Unterstützung erhält die Ortswehr durch die 14 weiteren Ortsfeuerwehren der Gemeinde Beverstedt und/oder Wehren aus den Nachbargemeinden. 
        Erweiterte technische Hilfeleistungen mit hydraulischen Rettungsgeräten u.a. sowie die Vegetations- und Waldbrandbekämpfung 
        sind zusätzliche Aufgaben, die wir ausführen.
      <br></p>
      <div><button @click="isVisible1 = !isVisible1">Bilder</button>
      <div class="bild_parent" v-if="isVisible1">
        <div v-for="item in was" :key="item" class="bilder">
          <img :src="require(`@/components/images/aktiv/was/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div>

      <!--<br>
      <h2></h2>
      <p>
      <br></p>
      <div><button @click="isVisible2 = !isVisible2">Bilder</button>
      <div class="bild_parent" v-if="isVisible2">
        <div v-for="item in wettbewerbe" :key="item" class="bilder">
          <img :src="require(`@/components/images/aktiv/wettbewerbe/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> -->
      
      <br>
      <h2>Was die Einsatzabteilung noch so macht...</h2>
      <p>
        Für unsere Bevölkerung veranstalten wir regelmäßig ein Osterfeuer am Ostersamstag und stellen den Pfingstbaum am Pfingstsamstag auf. 
        Außerdem unterstützen wir die ortsansässigen Kindergärten, Schulen und Vereine bei der Absicherung (Ordnungsdienste) verschiedenster 
        Umzüge durch unser Dorf.
      <br></p>
      <div><button @click="isVisible3 = !isVisible3">Bilder</button>
      <div class="bild_parent" v-if="isVisible3">
        <div v-for="item in freizeit" :key="item" class="bilder">
          <img :src="require(`@/components/images/aktiv/freizeit/${item.url}`)" :alt="item.name"/>
        </div>
      </div> 
      </div> 
    
      <br>
      <h3>Kontakte</h3>
      <p>Marco Bräuer, Ortsbrandmeister</p>
      <p>Manfred Siedenburg, stellv. Ortsbrandmeister</p>
      <P>E-Mail: &#111;&#114;&#116;&#115;&#98;&#114;&#97;&#110;&#100;&#109;&#101;&#105;&#115;&#116;&#101;&#114;&#64;&#102;&#101;&#117;&#101;&#114;&#119;&#101;&#104;&#114;&#45;&#108;&#117;&#110;&#101;&#115;&#116;&#101;&#100;&#116;&#46;&#100;&#101;</P>
    </article>
</main>
  </div>
</template>

<script>

export default {
  name: 'Aktiv',
  data() {
    return {
      isVisible: false,
      isVisible1: false,
      isVisible2: false,
      isVisible3: false,
      //Hier Bilder allgemein zur Einsatzabteilung (Lunestedt) einfügen
      allgemein: [
      {url: 'einsatzabteilung.jpg', name: 'Gruppenbild der Einsatzabteilung der FF Lunestedt'},
      ],
      //Hier Bilder zum Übungsdienst und was die Aktiven machen einfügen
      was: [
        {url: 'traininglukas.jpg', name: 'Training mit der Firma Lukas'},
        {url: 'DLK.jpg', name: 'Bild von einer DLK im Einsatz'},
        {url: 'KLT.jpg', name: 'Bild vom Übungsdienst bei KLT Lunestedt'},
        {url: 'Brandeinsatz.jpg', name: 'Bild von einem Brandeinsatz'},
        {url: 'HLF.jpg', name: 'Bild vom HLF'},
        {url: 'MTW.jpg', name: 'Bild vom MTW'},
        {url: 'TLF.jpg', name: 'Bild vom TLF'},
      ],
      //Hier Bilder zu den Wettbewerben einfügen
      wettbewerbe: [
      ],
      //Hier Bilder zur Freizeit Aktivitäten und Fahrten einfügen
      freizeit: [
        {url: 'Osterfeuer.jpg', name: 'Osterfeuer Haufen'},
        {url: 'Osterfeuer-brennt.jpg', name: 'Osterfeuer brennt'},
        {url: 'Pfingstbaum-Platz.jpg', name: 'Pfingstbaum Platz'},
        {url: 'Pfingstbaum-Baumtruppe.jpg', name: 'Pfingstbaum Baumtruppe'},
      ],
    }
  },
  components: {

  }
}
</script>
<style scoped>
.aktivmain {
  margin-top: 20px;
}
.bild_parent {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: solid 1px;
  border-radius: 5px;
  border-color: var(--inverted-color);
  padding-bottom: 15px;
  margin-top: 10px;
}
.bilder {
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 15px;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  border-radius: 5px;
  border-color: var(--inverted-color);
  background-color: transparent;
  font-size: 20px;
  color: var(--inverted-color);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.3s;
}
button:hover {
  color: var(--complementary-color);
  transition: 0.3s;
}
h2 {
  color: var(--primary-color);
  margin-top: 50px;
}
h3 {
  margin-top: 50px;
}
</style>
